<template>
    <v-app>
        <AppVSystemBar/>

        <AppVAppBar/>

        <v-content>
            <router-view/>
        </v-content>
    </v-app>
</template>

<script>
    import {
        VApp,

        VContent,
    } from 'vuetify/lib'
    import AppVAppBar from '@/components/vuetify/AppBar'
    import AppVSystemBar from '@/components/vuetify/SystemBar'

    export default {
        name: 'default',

        components: {
            VApp,

            AppVAppBar,

            VContent,

            AppVSystemBar,
        },

        data() {
            return {}
        },
    }
</script>
